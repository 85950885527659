import * as Sentry from "@sentry/nextjs"

export function logError(error: Error, info?: string | Record<string, any>, verbose = true) {
  let extra: Record<string, any> = {}

  if (!info && error?.message) {
    extra = { message: error?.message }
  } else if (typeof info === "string") {
    extra = { message: info }
  } else if (typeof info === "object") {
    extra = {
      message: error?.message,
      ...info,
    }
  }

  if (verbose) {
    if (info && typeof info === "string") {
      console.error(info, error)
    } else {
      console.error(error)
    }
  }

  return Sentry.captureException(error, { extra })
}
